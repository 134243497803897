<template>
  <div
    class="card card--table"
  >
    <div
      v-b-toggle="`collapseentrepeneur`"
      class="card-header"
    >
      <h3 class="cart-title">
        <a data-action="collapse">{{ title }}</a> <span class="badge bg-light-success">{{ entrepeneurs ? entrepeneurs.length : 0 }}</span>
      </h3>
      <div class="heading-elements">
        <ul class="list-inline mb-0">
          <li>
            <a
              data-action="collapse"
            ><i data-feather="chevron-down" /></a>
          </li>
        </ul>
      </div>
    </div>
    <div class="card-content">
      <b-collapse
        :id="`collapseentrepeneur`"
      >
        <div class="card-content">
          <div class="card-header d-flex align-items-center justify-content-between">
            <div>
              <h4><strong>{{ entrepeneurs ? entrepeneurs.length : 0 }}</strong> entrepeneurs</h4>
            </div>
          </div>
          <div class="table-responsive-sm">
            <table class="table table-striped table-hover">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>ICREA member</th>
                  <th>Institution</th>
                  <th class="text-end">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="entrepeneur, index in entrepeneurs"
                  :key="`entrepeneur-${index}`"
                >
                  <td>{{ entrepeneur.name }}</td>
                  <td><span
                    class="badge"
                    :class="`bg-light-${entrepeneur.user_id ? 'success' : 'danger'}`"
                  >{{ entrepeneur.user_id ? 'Yes' : 'No' }}</span></td>
                  <td>{{ entrepeneur.host ? entrepeneur.host.name : entrepeneur.user_host }}</td>
                  <td class="text-end">
                    <a
                      v-if="entrepeneur.user_id"
                      class="btn btn-icon btn-icon rounded-circle btn-flat-dark me-1"
                      :href="$router.resolve({name: 'researcher.dashboard', params: { id: entrepeneur.user_id }, query: { actAs: $route.query.actAs }}).href"
                      target="_blank"
                    ><i data-feather="user" /></a>
                    <a
                      class="btn btn-icon btn-icon rounded-circle btn-flat-danger"
                      @click="deleteentrepeneur(index)"
                    ><i data-feather="trash-2" /></a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="card-body">
            <a
              v-b-toggle="`collapseentrepeneurAdd`"
              class="btn btn-sm btn-dark dis-ib"
            ><i data-feather="plus" /> Add entrepeneur</a>

            <b-collapse
              :id="`collapseentrepeneurAdd`"
              class="collapse mt-3"
            >
              <div class="card shadow-none bg-transparent border-secondary">
                <div class="card-body">
                  <h3>Add entrepeneur</h3>
                  <div class="row">
                    <div class="col-8">
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label d-block"
                        > {{ manualUser ? labels['entrepeneurs.name_not_icrea'] : labels['entrepeneurs.select_icrea'] }} <a
                          class="float-end text-danger"
                          @click="toggleManualUser"
                        ><i data-feather="alert-triangle" /> <u>Click here</u> if the entrepeneur is not in this list</a></label>
                        <v-select
                          v-if="!manualUser"
                          v-model="selectedentrepeneur"
                          label="name"
                          :options="users"
                          :get-option-key="option => option.id"
                          @search="onSearch({ name: $event }, 'users/fetchFakeUsers')"
                          @input="selectInstitution"
                        />
                        <input
                          v-else
                          v-model="selectedentrepeneur"
                          type="text"
                          class="form-control"
                          placeholder="Write the name of the entrepeneur"
                        >
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label"
                        >{{ labels['entrepeneurs.host_institution'] }}</label>
                        <v-select
                          v-model="host"
                          label="name"
                          :clearable="false"
                          :options="hosts"
                          :get-option-key="option => option.id"
                          @search="onSearch({ name: $event }, 'institutions/filter')"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="mb-2">
                    <a
                      class="btn btn-sm btn-outline-success me-1"
                      @click="createentrepeneur"
                    >Create entrepeneur</a>
                    <a
                      v-b-toggle="`collapseentrepeneurAdd`"
                      class="btn btn-sm btn-flat-danger"
                    >Cancel</a>
                  </div>
                </div>
              </div>
            </b-collapse>
          </div>
        </div>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import { BCollapse } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import vSelect from 'vue-select'

export default {
  components: {
    BCollapse,
    vSelect,
  },
  props: {
    title: { type: String, required: true },
    entrepeneurs: { type: Array, required: false, default: () => [] },
  },
  data() {
    return {
      manualUser: false,
      selectedentrepeneur: null,
      host: null,
    }
  },
  computed: {
    ...mapGetters({
      users: 'users/fakeUsers',
      hosts: 'institutions/institutions',
      labels: 'sectionLabels/itemLabels',
    }),
  },
  mounted() {
    setTimeout(() => {
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
  },
  methods: {
    async onSearch(search, store) {
      search.active = true
      if (search.name !== '') {
        await this.$store.dispatch(store, search)
      }
    },
    toggleManualUser() {
      this.manualUser = !this.manualUser
      this.selectedentrepeneur = null
    },
    createentrepeneur() {
      this.entrepeneurs.push({
        name: this.selectedentrepeneur.id ? this.selectedentrepeneur.name : this.selectedentrepeneur,
        host: this.host,
        user_id: this.selectedentrepeneur.id,
      })
      setTimeout(() => {
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 100)
    },
    deleteentrepeneur(index) {
      this.entrepeneurs.splice(index, 1)
    },
    selectInstitution() {
      const host = this.selectedentrepeneur.hosts[0]
      if (host) {
        this.host = host
      }
    },
  },
}
</script>
